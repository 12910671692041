@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
.HomeAbout{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    .wrapper{
        padding-bottom: 0px;
        .title{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            h1{
                margin: 0;
                color: #F2C367;
                font-size: 3.3vw;            
                text-align: left;
                font-family: zonapro;
                @media (max-width:1200px){
                    font-size: 3.8vw;
                }
                @media (max-width:700px){
                    font-size: 4.5vw;
                }
                @media (max-width:500px){
                    font-size: 5.5vw;
                }
                @media (max-width:400px){
                    font-size: 6vw;
                }
                &::first-letter{
                    text-transform: uppercase;
                }
            }
            img{
                cursor: pointer;
                max-width: 5.5vw;
                min-width: 5.5vw;
                max-height: 5.5vw;
                min-height: 5.5vw;
                margin-right: 2vw;
                @media (max-width:500px){
                    max-width: 9.5vw;
                    min-width: 9.5vw;
                    max-height: 9.5vw;
                    min-height: 9.5vw;
                }
            }
        }
        .cards{
            display: flex;
            flex-wrap: wrap;
            margin-top: 4vw;
            justify-content: space-between;
            .texts{
                display: flex;
                padding: 0 2.5vw;
                overflow: hidden;
                perspective: 500px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                @media (max-width:900px){
                    padding: 0 1.5vw;
                }
                .name{
                    font-size: 2vw;            
                    color: #F2C367;
                    font-weight: 400;
                    text-align: left;                       
                    &::first-letter{
                        text-transform: uppercase;
                    }
                    @media (max-width:1200px){
                        font-size: 2.1vw;
                    }
                    @media (max-width:900px){
                        font-size: 2.5vw;
                    }
                    @media (max-width:600px){
                        font-size: 3vw;
                    }
                    @media (max-width:500px){
                        font-size: 4.2vw;
                    }
                }   
                .anim-texts{
                    display: flex;
                    // .left-text, .right-text{
                    //     width: 50%;
                    //     overflow: hidden;
                    // }    
                    // .right-text{
                    //     .text{
                    //         margin-left: -100%;
                    //     }
                    // }        
                }
                .text{
                    width: 100%;
                    color: #F2C367;
                    text-align: left;
                    font-size: 1.3vw;
                    overflow: hidden;
                    line-height: 1.8vw;
                    position: relative;
                    @media (max-width:1200px){
                        font-size: 1.5vw;
                    }
                    @media (max-width:900px){
                        font-size: 2vw;
                        line-height: 2.5vw;
                    }
                    @media (max-width:600px){
                        font-size: 2.5vw;
                        line-height: 3vw;
                    }
                    @media (max-width:500px){
                        font-size: 4vw;
                        line-height: 3.5vw;
                    }                   
                }                
                .explore{
                    width: 230px;
                    height: 45px;
                    display: flex;
                    position: relative;
                    text-decoration: none; 
                    transform-style: preserve-3d;                         
                    &:hover{
                        .one{
                            transform: rotateX(-180deg);
                        }
                        .two{
                            transform: rotateX(0deg);
                        }
                    }      
                    @media (max-width:1200px){
                        height: 38px;
                    }  
                    @media (max-width:500px){
                        height: 40px;
                    }        
                    .one{
                        top: 0;
                        left: 0;
                        z-index: 2;
                        color: black;
                        position: absolute;
                        padding: 10px 50px;
                        border-radius: 7px;
                        transition: 0.4s linear;                  
                        backface-visibility: hidden;
                        background-color: #F2C367;                        
                        @media (max-width:1200px){
                            font-size: 14px;
                            padding: 7px 40px;
                        }
                        @media (max-width:500px){
                            font-size: 16px;
                            padding: 8px 42px;
                        }
                    }
                    .two{
                        top: 0;
                        left: 0;
                        color: black;                                     
                        transition: 0.4s linear;  
                        position: absolute;
                        padding: 10px 50px;
                        border-radius: 7px;
                        background-color: white;
                        backface-visibility: hidden;
                        transform: rotateX(180deg);
                        @media (max-width:1200px){
                            font-size: 14px;
                            padding: 7px 40px;
                        }
                        @media (max-width:500px){
                            font-size: 16px;
                            padding: 8px 42px;
                        }
                    }
                }
            }
            .society{
                padding: 0 2.5vw;
                @media (max-width:900px){
                    padding: 0 1.5vw;
                }
                img{
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 25vw;
                    min-height: 25vw;
                    @media (max-width:1200px){
                        max-height: 26vw;
                        min-height: 26vw;
                    }
                    @media (max-width:900px){
                        max-height: 30vw;
                        min-height: 30vw;
                    }
                    @media (max-width:600px){
                        max-height: 33vw;
                        min-height: 33vw;
                    }
                    @media (max-width:500px){
                        max-height: 37vw;
                        min-height: 37vw;
                    }
                }
            }
        }
    }
}
.media{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000001;
    .video{                
        height: 30vw;
        overflow: hidden;
        border-radius: 2vw;
        border: solid 1px white;
        @media (max-width:767px){
            width: 100%;
            margin-top: 4vw;
            height: 50vw;
        }                
        iframe{
            max-width: 100%;
            min-width: 100%;
            max-height: 100%;
            min-height: 100%;
            position: sticky;
            z-index: 1000001;
        }
    }
}

@keyframes circleAnimation {
    50%{
        left: calc(100% - 13vw);
    }
    100%{
        left: 0;
    }
}