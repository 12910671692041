.Loader {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: end;
    background-color: #3F2328;

    .pacman {
        left: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        display: flex;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        animation-name: pacman;
        animation-duration: 7s;
        justify-content: center;
        border: solid 6.5vw #F2C367;
        animation-timing-function: linear;
        border-right: solid 6.5vw transparent;
        @media(max-width:500px){
            border: solid 10vw #F2C367;
            border-right: solid 10vw transparent;
        }

        .pacman1,
        .pacman2 {
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            border-radius: 50%;
            animation-duration: 1.5s;
            border: solid 6.5vw #F2C367;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            border-right: solid 6.5vw transparent;
            @media(max-width:500px){
                border: solid 10vw #F2C367;
                border-right: solid 10vw transparent;
            }
        }

        .pacman1 {
            animation-name: pacman1;
        }

        .pacman2 {
            animation-name: pacman2;
        }
    }

    .imgs {
        left: 10%;
        width: 80%;
        z-index: -1;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: space-between;
        
        img {
            max-width: 6.5vw;
            animation-name: macaron;
            animation-duration: 7.75s;
            animation-timing-function: linear;

            &:nth-child(2) {
                animation-delay: 1.4s;
            }

            &:nth-child(3) {
                animation-delay: 2.8s;
            }

            &:nth-child(4) {
                animation-delay: 4.1s;
            }

            &:nth-child(5) {
                animation-delay: 5.5s;
            }
        }
    }
}

@keyframes pacman {
    100% {
        margin-left: 100%;
    }
}

@keyframes pacman1 {
    50% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes pacman2 {
    50% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes macaron {
    7% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}