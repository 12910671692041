@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
.Tour{
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 30vw;
    margin: 50px 0;
    display: flex;
    position: sticky;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    @media(max-width:700px){
        height: 50vw;
        margin: 30px 0;
    } 
    @media(max-width:500px){
        height: 55vw;
        margin: 20px 0;
    } 
    .back-tour{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .close-icon{
        top: 20px;
        color: white;
        cursor: pointer;
        font-size: 2.5vw;
        position: absolute;
        border-radius: 50%;
        border: solid 1px white;
        @media(max-width:700px){
            font-size: 4vw;
        }
        @media(max-width:500px){
            top: 10px;
            font-size: 5vw;
        }
    }
    .contr{
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        position: sticky;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.42);
        .text{
            cursor: pointer;
            color: white;
            font-size: 2vw;            
            font-family: zonapro;
            position: absolute;
            margin-top: 13vw;
            @media(max-width:700px){
                font-size: 3vw;
            }
            @media(max-width:500px){
                font-size: 2.5vw;
                margin-bottom: 0;
            }
        }
        .title{
            color: white;
            font-size: 8vw;
            font-family: zonapro;
            position: absolute;
            @media(max-width:700px){
                font-size: 9vw;
            }
            @media(max-width:500px){
                font-size: 9.5vw;                
            }
        }
        .front-tour {
            min-width: 21vw;
            max-width: 21vw;
            cursor: pointer;
        }
    }
}