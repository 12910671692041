.Icons {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  .title_name {
    text-align: center;
    color: #f2c367;
    padding: 1rem;
    font-weight: bolder;
    font-size: 3vw;
    @media (max-width: 500px) {
      font-size: 4.5vw;
    }
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 600px) {
      padding-bottom: 10px;
    }
    .col-3 {
      padding: 0 17px;
      @media (max-width: 1100px) {
        padding: 0 15px;
      }
      @media (max-width: 600px) {
        width: 50%;
        display: block;
        padding: 0 10px 7vw;
        grid-template-columns: repeat(2, 2fr);
      }
      @media (max-width: 500px) {
        width: 100%;
        display: block;
        padding: 0 10px 7vw;
        grid-template-columns: repeat(1, 1fr);
      }
      .body {
        text-align: center;
        height: 100%;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        .body__img {
          min-height: 70px;
          @media (max-width: 600px) {
            min-height: 50px;
          }
          .img {
            max-width: 50%;
            @media (max-width: 1900px) {
              max-width: 60%;
            }
            @media (max-width: 600px) {
              max-width: 40%;
            }
          }
          // .dollar {
          //   color: #F2C367;
          // }
          .img4 {
              max-width: 50%;
              @media (max-width: 500px) {
                max-width: 25%;
              }
          }
        }
        // .img2 {
        //     max-width: 45%;
        // }
        .body__name {
          padding-top: 3vw;
          min-height: 40px;
          margin: 5px 0;
          @media (max-width: 1700px) {
            min-height: 20px;
          }
          @media (max-width: 1265px) {
            min-height: 40px;
          }
          @media (max-width: 600px) {
            margin: 5px 0;
          }
          .name {
            width: 100%;
            color: #f2c367;
            text-align: center;
            font-size: 1.3vw;
            @media (max-width: 1400px) {
              font-size: 15px;
              // margin-top: 15px;
            }
            @media (max-width: 900px) {
              font-size: 13px;
              // margin-top: 10px;
            }
            @media (max-width: 600px) {
              font-size: 11px;
            }
            @media (max-width: 500px) {
              font-size: 15px;
              // margin-top: 5px;
            }
          }
        }
        .desc {
          width: 100%;
          margin-top: 30px;
          color: #f2c367;
          font-weight: 300;
          text-align: center;
          @media (max-width: 1400px) {
            font-size: 14px;
            margin-top: 10px;
          }
          @media (max-width: 900px) {
            font-size: 12px;
            margin-top: 10px;
          }
          @media (max-width: 600px) {
            font-size: 11px;
          }
          @media (max-width: 500px) {
            font-size: 14px;
            margin-top: 5px;
          }
        }
        .desc1 {
          margin-bottom: 2.5vw;
        }
        .desc2 {
          margin-bottom: 4vw;
        }
        .desc3 {
          margin-bottom: 2.5vw;
        }
      }
    }
  }
}
