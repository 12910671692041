.Navbar{
    background-color: #3F2328;
    transition: 0.2s linear;
    position: fixed;
    width: 100vw;
    z-index: 30;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    .navbar{
        justify-content: space-between;
        transition: 0.2s linear;
        align-items: center;
        padding: 10px 7vw;
        display: flex;
        width: 100vw;
        height: 100%;
        margin: 0;  
        @media(max-width:1300px){
            padding: 25px 5vw;
        } 
        @media(max-width:1050px){
            padding: 20px 3vw;
        } 
        @media(max-width:767px){
            padding: 20px 7vw;
        }
        @media(max-width:500px){
            padding: 10px 7vw;
        }
        @media(max-width:430px){
            padding: 2px 7vw;
        }     
        .logo{
            width: 9vw;
            @media(max-width:1300px){
                width: 13vw;  
            }
            @media(max-width:600px){
                width: 20vw;  
            }
            @media(max-width:430px){
                width: 23vw;  
            }                        
            img{
                width: 100%;
            }
        }
        .overlay{
            transition: 0.3s ease left, width, opacity;
            background-color: white;
            position: absolute;
            border-radius: 5px;
            z-index: -1;
            opacity: 0;
            left: 0;
            top: 0;
        }
        .active{
            opacity: 1;
        }
        .navbar-nav{
            justify-content: space-between;
            transition: 0.4s linear;
            align-items: center;
            padding: 0 100px;
            display: flex;
            width: 100%;
            margin: 0;
            @media(max-width:1450px){
                padding: 0 50px;   
            }
            @media(max-width:1150px){
                padding: 0 20px;   
            }
            @media(max-width:950px){
                padding: 0 10px;   
            }
            @media(max-width:767px){
                background-color: #3F2328;
                flex-direction: column;
                padding-bottom: 200px;
                position: absolute; 
                padding: 50px 0;
                height: 60vh;
                width: 100vw;
                z-index: 20;
                top: -60vh;
                right: 0;
            }
            .nav-item{
                justify-content: center;
                align-items: center;
                display: flex;
                width: 100%;
                padding: 0;
                margin: 0;
                &:hover{
                    .nav-link{
                        color: #3F2328;
                    }
                }
                .nav-link{
                    position: relative;
                    padding: 5px 10px;
                    color: white;
                    margin: 0;
                    @media(max-width:1300px){
                        font-size: 14px;
                    }
                    @media(max-width:1050px){
                        font-size: 13px;
                    }
                    @media(max-width:950px){
                        font-size: 12px;
                    }
                    @media(max-width:767px){
                        font-size: 18px; 
                    }
                    @media(max-width:500px){
                        font-size: 16px; 
                    }
                    
                }
                .language{
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    display: flex;
                    input[type="checkbox"]{
                        display: none;
                    }
                    label{
                        background-color: rgb(255, 255, 255);
                        box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
                        backdrop-filter: blur(5px);                    
                        border-radius: 6px;
                        transition: 0.2s;
                        padding: 4px 10px;
                        cursor: pointer;
                        color: black;
                        &:hover{
                            background-color: white;
                            color: black;
                            .fa-angle-down{
                                color: black;
                            }
                        }
                        @media(max-width:1300px){
                            font-size: 14px;
                        }
                        @media(max-width:1050px){
                            font-size: 13px;
                        }
                        @media(max-width:950px){
                            font-size: 12px;
                        }
                        @media(max-width:767px){
                            font-size: 18px; 
                            border: solid 1px white;
                        }
                        @media(max-width:500px){
                            font-size: 16px; 
                        }
                        .fa-angle-down{
                            color: black;
                        }
                    }
                    .language-menu{
                        background-color: black;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        border-radius: 5px;
                        position: absolute;
                        overflow: hidden;
                        z-index: 10001;
                        display: flex;
                        width: 3.7vw;
                        padding: 0;
                        margin: 0;
                        top: 32px;
                        @media(max-width:767px){
                            width: 70px;
                            margin-top: 10px;                            
                            border: solid 1px white;
                            padding: 0 8px;
                            overflow: inherit;
                        }                        
                        li{
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            list-style: none;
                            display: flex;
                            width: 100%;
                            padding: 0;
                            margin: 0;
                            .lang-link{
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                padding: 5px 20px;
                                cursor: pointer;
                                color: white;
                                display: flex;
                                width: 100%;
                                margin: 0;
                                &:hover{
                                    background-color: white;
                                    color: black;
                                }
                                @media(max-width:1300px){
                                    font-size: 14px;
                                }
                                @media(max-width:1050px){
                                    font-size: 13px;
                                }
                                @media(max-width:767px){
                                    font-size: 18px;                                     
                                }
                                @media(max-width:500px){
                                    font-size: 16px; 
                                }
                            }
                        }
                    }
                }
            }
            .nav-tel{
                @media(min-width:767.1px){
                    display: none;
                }
                .nav-link{
                    &::before, &::after{
                        display: none;                        
                    }
                }
            }
            .close-icon{
                position: absolute;
                font-size: 30px;
                cursor: pointer;
                color: white;
                right: 30px;
                top: 20px;
                @media(min-width:767.1px){
                    display: none;
                }
            }
        }
        .open{
            transition: 0.4s linear;
            top: 0;
        }
        .tel-number{
            background-color: #F2C367;
            justify-content: center;            
            text-decoration: none;
            align-items: center;
            border-radius: 9px;
            padding: 10px 22px;
            display: flex;      
            &:hover{
                background-color: white;
            }      
            @media(max-width:1300px){
                border-radius: 8px;
                padding: 8px 20px;
            }
            @media(max-width:1050px){                    
                border-radius: 6px;
                padding: 7px 15px;
            }
            @media(max-width:767px){
                display: none;
            }
            img{
                margin-right: 10px;
                width: 25%;
                @media(max-width:1050px){                    
                    margin-right: 6px;                     
                }
            }
            .tel{               
                margin-left: 10px; 
                font-weight: 500;
                color: #3F2328;
                font-size: 16px;
                padding: 0;
                margin: 0;
                @media(max-width:1300px){
                    font-size: 14px;
                }
                @media(max-width:1050px){                    
                    margin-left: 6px; 
                    font-size: 13px;
                }
                @media(max-width:950px){
                    font-size: 12px;
                }
            }           
        }
        .open-icon{
            color: white;
            @media(min-width:767.1px){
                display: none;
            }
        }
    }
}

.black{
    background-color: #3F2328;
    transition: 0.2s linear;
    .navbar{
        padding: 8px 7vw;
        transition: 0.2s linear;
        @media(max-width:1300px){
            padding: 7px 5vw;
        } 
        @media(max-width:1050px){
            padding: 5px 3vw;
        }
        @media(max-width:767px){
            padding: 5px 7vw;
        }
        @media(max-width:500px){
            padding: 2px 7vw;
        }
    }
}