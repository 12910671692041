@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}
.Comments {
  .wrapper {
    margin: 0;
    padding: 0;
    overflow: hidden;
    .name {
      color: #f2c367;
      font-size: 3.5vw;
      text-align: center;
      padding-bottom: 7vw;
      font-family: zonapro;
      @media (max-width: 767px) {
        font-size: 4vw;
      }
      @media (max-width: 500px) {
        font-size: 5vw;
      }
    }
    .carousel {
      .slick-list {
        overflow: inherit !important;
      }
      .slick-prev,
      .slick-next {
        visibility: hidden;
      }
      .slide-active{
        .body{
            transform: scale(1.3);
            .explore{
                opacity: 1;
                display: flex;
                transition: 1s;
                visibility: visible;
            }
        }
    }
      .carousel-page {
        padding: 0 5vw;
        .body {
          width: 100%;
          padding: 1vw 3vw;
          border-radius: 0.8vw;
          border: 1px solid #f2c367;
          background: rgba(255, 255, 255, 0.035);
          img {
            position: absolute;
            max-width: 7vw;
            min-width: 7vw;
            max-height: 7vw;
            min-height: 7vw;
            border-radius: 50%;
            top: -2.5vw;
          }
          h1 {
            color: #f2c367;
            font-size: 1.5vw;
            font-family: zonapro;
            margin: 0 0 0 8vw;
            @media (max-width: 767px) {
              font-size: 2vw;
            }
          }
          .dates {
            margin-left: 25vw;
            border-radius: 0.5vw;
            background-color: #f2c4671f;
            .date {
              text-align: end;
              color: #f2c367;
              font-size: 0.8vw;
              padding: 5px 10px;
              text-align: center;
              margin: -1.8vw 0 2.5vw 0;
              @media (max-width: 767px) {
                margin: -2.2vw 0 2.5vw 0;
                padding: 5px 7px;
                font-size: 1vw;
              }
              @media (max-width: 500px) {
                margin: -2.2vw 0 2.5vw 0;
                padding: 2.5px 3px;
                font-size: 1vw;
              }
            }
            // @media (max-width: 767px) {
            //   font-size: 1vw;
            // }
          }
          .desc {
            color: #f2c367;
            font-size: 1vw;
            font-family: zonapro;
            @media (max-width: 767px) {
              font-size: 1.5vw;
            }
          }
        }
        @media (max-width: 500px) {
          padding: 0 15vw;
        }
      }
      padding-bottom: 7vw;
    }
  }
}
