.Infografics {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  .wrapper {
    .title {
      color: #f2c367;
      font-size: 3.3vw;
      text-align: center;
      font-family: zonapro;
      @media (max-width: 1200px) {
        font-size: 3.8vw;
      }
      @media (max-width: 700px) {
        font-size: 4.5vw;
      }
      @media (max-width: 500px) {
        font-size: 5.5vw;
      }
      @media (max-width: 400px) {
        font-size: 6vw;
      }
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .numbers {
      width: 100%;
      display: flex;
      margin-top: 4vw;
      flex-wrap: wrap;
      position: relative;
      justify-content: space-between;
      @media (max-width: 700px) {
        margin-top: 5vw;
      }
      .circle {
        left: 0;
        top: -50%;
        z-index: 2;
        width: 9vw;
        height: 9vw;
        position: absolute;
        border-radius: 50%;
        animation-duration: 5s;
        animation-name: circleAnimation1;
        backdrop-filter: grayscale(100%);
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
      .col-4 {
        width: 33%;
        padding: 2vw 20px;
        @media (max-width: 600px) {
          padding: 0 10px;
        }
        .body {
          display: flex;
          position: relative;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .number {
            display: flex;
            font-size: 4.2vw;
            color: #f2c367;
            align-items: center;
            font-family: zonapro;
            @media (max-width: 700px) {
              font-size: 5vw;
            }
            @media (max-width: 500px) {
              font-size: 6.5vw;
            }
            .span {
                font-size: 2.5vw;
            }
            .technology {
              max-width: 45%;
              min-width: 45%;
              margin-left: 2vw;
              @media (max-width: 1200px) {
                max-width: 30%;
                min-width: 30%;
                margin-left: 5vw;
              }
              @media (max-width: 1000px) {
                max-width: 30%;
                min-width: 30%;
                margin-left: 5vw;
              }
              @media (max-width: 800px) {
                max-width: 30%;
                min-width: 30%;
                margin-left: 8vw;
              }
            }
          }
          .text {
            font-size: 18px;
            font-weight: 300;
            color: #f2c367;
            margin-top: 10px;
            text-align: center;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
            @media (max-width: 700px) {
              font-size: 14px;
            }
            @media (max-width: 600px) {
              font-size: 12px;
            }
            @media (max-width: 430px) {
              font-size: 13px;
            }
          }
          .leaf {
            top: 0;
            left: 0%;
            width: 20%;
            z-index: -1;
            position: absolute;
            @media (max-width: 500px) {
              top: 5%;
              left: 5%;
            }
          }
        }
      }
    }
  }
}

@keyframes circleAnimation1 {
  0% {
    left: 0;
    top: -200%;
  }
  20% {
    top: 0%;
    left: 0%;
  }
  // 40% {

  // }
  40% {
    top: 0%;
    left: 82%;
  }
  60% {
    top: 55%;
    right: 150%;
  }
  80% {
    top: 55%;
    left: 10%;
  }
  100% {
    top: 55%;
    // left: 10%;
    right: 150%;
  }
}
