.Footer{
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #F2C367;
    .wrapper{
        padding: 50px 7vw;
        @media(max-width:1150px){
            padding: 50px 7vw;
        }
        @media(max-width:1000px){
            padding: 50px 5vw;
        }
        .footer-tools{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            @media(max-width:700px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .tool{
                padding: 30px 20px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                @media(max-width:1450px){                            
                    padding: 30px 15px;
                }
                @media(max-width:1100px){                            
                    padding: 20px 10px;
                }
                @media(max-width:1000px){                            
                    padding: 10px;
                }
                @media(max-width:700px){
                    width: 100%;
                    padding: 10px 15px;
                    margin-bottom: 30px;
                }
                @media(max-width:500px){                    
                    padding: 10px;
                }
                @media(max-width:370px){                    
                    padding: 5px;
                }
                img{
                    width: 50%;
                }
                .text{
                    margin-top: 20px;
                    font-family: 'Rubik', sans-serif;
                    @media(max-width:1350px){                            
                        font-size: 14px;
                    }
                    @media(max-width:1100px){                            
                        font-size: 13px;
                    }
                    @media(max-width:500px){                            
                        font-size: 12px;
                    }
                }
                .icons{
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    align-items: center;
                    justify-content: start;   
                    .icon{
                        &:hover{
                            .fa{
                                transform: rotate(360deg) scale(1.3);
                            }
                        }
                    }                                                     
                    .fa{
                        color: #3F2328;
                        font-size: 30px;
                        margin-right: 50px;
                        transition: 0.5s;
                        @media(max-width:1350px){                            
                            margin-right: 40px;
                        }
                        @media(max-width:1150px){                            
                            font-size: 25px;
                            margin-right: 35px;
                        }
                        @media(max-width:900px){                            
                            font-size: 20px;
                            margin-right: 30px;
                        }
                    }
                }
                ul{
                    margin: 0;
                    padding: 0;   
                    width: 100%;                   
                    li{
                        margin: 0;
                        padding: 0;
                        width: 100%;                      
                        color: black;
                        list-style: none;  
                        font-family: 'Rubik', sans-serif;
                        @media(max-width:1350px){                            
                            font-size: 14px;
                        }
                        @media(max-width:1100px){                            
                            font-size: 13px;
                        }
                        @media(max-width:500px){                            
                            font-size: 12px;
                        }
                    }
                    .link{
                        margin-top: 20px;
                        color: rgba(0, 0, 0, 0.468);
                        font-family: 'Rubik', sans-serif;
                        &:hover{
                            text-decoration: underline;
                        }
                        @media(max-width:1350px){                            
                            font-size: 14px;
                        }
                        @media(max-width:1100px){                            
                            font-size: 13px;
                        }
                        @media(max-width:500px){                            
                            font-size: 12px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
    .copywrite{
        display: flex;
        padding: 30px 0;      
        margin-top: 20px;        
        align-items: center;  
        justify-content: center;                
        background-color: white;
        @media(max-width:600px){                            
            padding: 20px 0;
            flex-direction: column;
        }
        .res{
            display: flex;
        }
        .reserve{
            margin: 0;
            padding: 0;
            display: flex;
            color: rgba(0, 0, 0, 0.65);
            align-items: center;
            justify-content: center;
            font-family: 'Rubik', sans-serif;
            @media(max-width:1350px){                            
                font-size: 14px;
            }
            @media(max-width:1100px){                            
                font-size: 13px;
            }
            @media(max-width:600px){                            
                font-size: 11px;
            }
        }               
        .yellow{
            padding: 0 6px;
            color: #F2C367;            
        }        
    }
}