@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
.Categories{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    position: sticky;
    align-items: center;
    justify-content: center;
    .wrapper{        
        .title{
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .cards{
            display: flex;
            flex-wrap: wrap;
            margin-top: 4vw;
            justify-content: center;
            @media (max-width:767px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .category{
                padding: 1.1vw;
                @media (max-width:767px){
                    width: 100%;
                    padding: 1.7vw;
                }
                .body{
                    height: 100%;
                    display: flex;
                    padding: 2.6vw;
                    overflow: hidden;
                    transition: 0.3s;
                    position: relative;
                    align-items: center;
                    border-radius: 1.2vw;
                    text-decoration: none;
                    flex-direction: column;
                    // backdrop-filter: blur(5px);
                    justify-content: space-between;
                    background-color: rgba(255, 255, 255, 0.1);
                    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
                    border-top: solid 1px rgba(255, 255, 255, 0.5);
                    border-left: solid 1px rgba(255, 255, 255, 0.5);
                    -webkit-border-radius: 1.2vw;
                    -moz-border-radius: 1.2vw;
                    -ms-border-radius: 1.2vw;
                    -o-border-radius: 1.2vw;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;
                    &:hover{
                        transform: scale(1.05);
                        -webkit-transform: scale(1.05);
                        -moz-transform: scale(1.05);
                        -ms-transform: scale(1.05);
                        -o-transform: scale(1.05);
                        .contr-body{                            
                            transform: scale(2);    
                        }
                        .name{
                            color: #3F2328;
                        }
                    }
                    @media (max-width:767px){
                        padding: 3.7vw;
                        border-radius: 2vw;
                        -webkit-border-radius: 2vw;
                        -moz-border-radius: 2vw;
                        -ms-border-radius: 2vw;
                        -o-border-radius: 2vw;
                    }
                    .contr-body{
                        // top: 0;
                        // left: 0;
                        z-index: -1;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        position: absolute;                        
                        align-items: center;
                        transform: scale(0);
                        // transform-origin: top;                        
                        justify-content: center;
                        transition: 0.3s linear;
                        border-radius: 50%;
                        background-color: #FFDB9B;                       
                    }
                    .name{
                        color: white;
                        font-size: 1.1vw;
                        text-align: center;
                        font-family: zonapro;
                        &::first-letter{
                            text-transform: uppercase;
                        }
                        @media (max-width:1100px){
                            font-size: 1.5vw;
                        }
                        @media (max-width:767px){
                            font-size: 2.5vw;
                        }
                        @media (max-width:500px){
                            font-size: 3.2vw;
                        }
                    }
                    img{
                        max-width: 100%;
                        min-width: 100%;
                        margin-top: 1.5vw;
                        max-height: 15.6vw;
                        min-height: 15.6vw;
                        border-radius: 1.15vw;
                        -webkit-border-radius: 1.15vw;
                        -moz-border-radius: 1.15vw;
                        -ms-border-radius: 1.15vw;
                        -o-border-radius: 1.15vw;
                        @media (max-width:767px){
                            max-height: 26vw;
                            min-height: 26vw;
                        }
                        @media (max-width:500px){
                            max-height: 30vw;
                            min-height: 30vw;
                        }
                    }
                }
            }
        }
    }
    .mac1{
        top: 10vw;
        left: 5vw;
        max-width: 7vw;
        min-width: 7vw;
        position: absolute;
        transform: rotate(70deg);
        animation-name: mac2;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        @media (max-width:767px){
            top: 50%;
            left: 3vw;
            max-width: 9vw;
            min-width: 9vw;
        }
        @media (max-width:500px){
            display: none;
        }
    }
    .mac2{
        top: 10vw;
        right: -3vw;
        max-width: 15vw;
        min-width: 15vw;
        filter: blur(3px);
        position: absolute;
        animation-name: mac2;
        animation-duration: 3s;
        transform: rotate(70deg);
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        @media (max-width:767px){
            top: 13vw;
            right: -6vw;
            max-width: 18vw;
            min-width: 18vw;
        }
        @media (max-width:500px){
            display: none;
        }
    }
}