.SuspenseLoader{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 100001;
    align-items: center;
    justify-content: center;
    background-color: #F2C367;
    .shapes{
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        .square{
            left: -9vw;
            width: 0.8vw;
            height: 0.8vw;
            border-radius: 50%;
            position: absolute;
            animation-duration: 2.2s;
            border: solid 3px #3F2328;
            animation-name: suspenseAnim;            
            animation-iteration-count: infinite;
        }
        @keyframes suspenseAnim {
            33%{
                width: 1.8vw;
                height: 1.8vw;
                border: solid 3px white;
                background-color: white;
            }
            66%{
                width: 0.8vw;
                height: 0.8vw;
                border: solid 3px #3F2328;
            }
            100%{
                width: 0.8vw;
                height: 0.8vw;
                border: solid 3px #3F2328;
            }
        }
    }
}