@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}
@font-face {
  font-family: zonapro1;
  src: url("../../../assets/fonts/zonapro1.ttf");
}
@font-face {
  font-family: craft;
  src: url("../../../assets/fonts/CraftworkGrotesk500\ \(2\).ttf");
}
.Sertificat {
  .wrapper {
    h1 {
      font-size: 3.5vw;
      color: #f2c367;
      text-align: center;
      padding-bottom: 3vw;
      font-family: zonapro;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
      @media (max-width: 767px) {
        font-size: 5vw;
      }
    }
    .cards {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 767px) {
        width: 100%;
        display: block;
        grid-template-columns: repeat(1, 1fr);
      }
      .sertificts {
        padding: 0 1vw;
        margin: 0.6vw 0;
        @media (max-width: 767px) {
          width: 100%;
          display: block;
          grid-template-columns: repeat(1, 1fr);
        }
        .body {
          height: 100%;
          display: flex;
          .card {
            width: 50vw;
            padding: 1vw 2vw;
            color: #3f2328;
            border-radius: 1vw;
            background-color: #ffdb9b;
            @media (max-width: 767px) {
              width: 100%;
            }
            p {
              margin: 0;
              font-size: 1vw;
              color: #3f2328;
              font-family: craft;
              padding: 0 0 1vw 0;
              @media (max-width: 900px) {
                font-size: 1.8vw;
              }
              @media (max-width: 750px) {
                font-size: 1.9vw;
              }
              @media (max-width: 600px) {
                font-size: 1.7vw;
              }
              @media (max-width: 500px) {
                font-size: 3.5vw;
              }
            }
            h3 {
              color: #3f2328;
              font-size: 1.2vw;
              font-weight: 900;
              font-family: craft;
              @media (max-width: 900px) {
                font-size: 2vw;
              }
              @media (max-width: 750px) {
                font-size: 2.2vw;
              }
              @media (max-width: 600px) {
                font-size: 1.8vw;
              }
              @media (max-width: 500px) {
                font-size: 4vw;
              }
            }
          }
        }
      }
    }
  }
}
