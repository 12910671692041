.row{
  --bs-gutter-x: 0;
}

.wrapper{
  width: 100%;
  height: 100%;
  padding: 50px 15vw;
  position: relative;
  @media(max-width:1000px){
    padding: 50px 13vw;
  }
  @media(max-width:700px){
    padding: 30px 10vw;
  }  
  @media(max-width:500px){
    padding: 30px 7vw;
  }
}

.contrast-0{
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
}

.contrast{
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.48);
}

.contrast-05{
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.268);
}