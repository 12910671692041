@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
.Gallery{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    .wrapper1{
        .title{
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;            
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .carousel{
            margin: 0;
            padding: 0;
            margin-top: 4vw;
            overflow: inherit;
            padding-bottom: 2.5vw;
            .slick-list{
                overflow: inherit;
            }
            .carousel-page{
                width: 100%;
                height: 100%;
                padding: 50px 16vw;
                @media(max-width:1000px){
                    padding: 50px 14vw;
                }
                @media(max-width:700px){
                    padding: 30px 11vw;
                }  
                @media(max-width:500px){
                    padding: 30px 8vw;
                }
                .body{
                    height: 38vw;
                    overflow: hidden;
                    position: relative;
                    border-radius: 2.1vw;
                    @media(max-width:700px){
                        height: 45vw;
                    }
                    @media(max-width:500px){
                        height: 50vw;
                    }
                    img{
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
            .slick-prev, .slick-next{
                opacity: 0;
            }
            .slick-dots{
                opacity: 1;
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;                           
                li{       
                    padding: 0 10px;                         
                    button{                                      
                        &::before{
                            width: 11px;
                            height: 11px;
                            display: flex;
                            color: #F2C367;
                            border-radius: 50%;
                            align-items: center;
                            justify-content: center;
                            background-color: #F2C367;
                            @media (max-width:1000px){
                                width: 9px;
                                height: 9px;
                            }
                            @media (max-width:500px){
                                width: 8px;
                                height: 8px;
                            }
                        }
                    }
                }       
                .slick-active{
                    transition: 0.5s;
                    button{        
                        transition: 0.5s;            
                        &::before{
                            opacity: 1;
                            width: 30px;
                            height: 11px;
                            display: flex;
                            transition: 0.5s;
                            margin-left: -8px;
                            border-radius: 6px;
                            align-items: center;
                            justify-content: center;
                            background-color: #F2C367;
                            @media (max-width:1000px){
                                width: 22px;
                                height: 8px;
                                border-radius: 4px;
                            }
                            @media (max-width:500px){
                                width: 20px;
                                height: 7px;
                                border-radius: 4px;
                            }
                        }
                    }
                }     
            }
        }
    }
}