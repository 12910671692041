.OneRetsep {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  .wrapper {
    display: flex;
    padding-top: 170px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 767px) {
      padding-top: 130px;
      padding-bottom: 0;
    }
    @media (max-width: 500px) {
      padding-top: 100px;
    }
    @media (max-width: 400px) {
      padding-top: 80px;
    }
    .title {
      margin: 0;
      color: #f2c367;
      font-size: 3.3vw;
      text-align: center;
      font-family: zonapro;
      @media (max-width: 1200px) {
        font-size: 3.8vw;
      }
      @media (max-width: 700px) {
        font-size: 4.5vw;
      }
      @media (max-width: 500px) {
        font-size: 5.5vw;
      }
      @media (max-width: 400px) {
        font-size: 6vw;
      }
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .cards {
      display: flex;
      margin-top: 4vw;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 600px) {
        display: block;
      }
      .image {
        @media (max-width: 600px) {
          width: 100%;
        }
        img {
          max-width: 100%;
          min-width: 100%;
          border-radius: 1.5vw;
        }
      }
      .texts {
        padding-left: 4vw;
        @media (max-width: 600px) {
          padding: 0;
          margin-top: 4vw;
          width: 100%;
        }
        p {
          font-size: 18px;
          color: #f2c367;
          text-align: left;
          @media (max-width: 1250px) {
            font-size: 16px;
          }
          @media (max-width: 900px) {
            font-size: 14px;
          }
          @media (max-width: 430px) {
            font-size: 13px;
          }
        }
      }
      .description {
        margin: 0;
        padding: 0;
        padding-top: 5vw;
      }
      .video {
        margin-top: 4vw;
        overflow: hidden;
        border-radius: 20px;
        @media (max-width: 500px) {
          border-radius: 10px;
        }
        iframe {
          width: 100%;
          height: 40vw;
          border-radius: 20px;
          @media (max-width: 500px) {
            height: 60vw;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
