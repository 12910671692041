@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
@font-face {
    font-family: zonapro1;
    src: url("../../../assets/fonts/zonapro1.ttf");
}
.News{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    .wrapper{
        .title{
            margin: 0;
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .cards{
            display: flex;
            padding: 2.5vw;
            flex-wrap: wrap;
            // align-items: center;
            justify-content: space-between;
            @media (max-width:1100px){
                padding: 0;
                margin-top: 4vw;
            }
            @media (max-width:767px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .new{
                padding: 1.2vw;
                perspective: 800px;
                @media (max-width:767px){
                    width: 100%;
                    padding: 1.6vw;
                }                
                .body{
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    border-radius: 1.1vw;
                    transform-style: preserve-3d;
                    -webkit-border-radius: 1.1vw;
                    -moz-border-radius: 1.1vw;
                    -ms-border-radius: 1.1vw;
                    -o-border-radius: 1.1vw;
                    background-color: #F2C367;
                    @media (max-width:767px){
                        border-radius: 1.4vw;
                    }
                    @media (max-width:500px){
                        border-radius: 2.5vw;
                    }
                    .stamp{
                        top: 20px;
                        right: 20px;
                        color: white;
                        font-size: 10px;
                        padding: 1px 7px;
                        position: absolute;
                        border-radius: 7px;
                        text-transform: uppercase;
                        -webkit-border-radius: 7px;
                        -moz-border-radius: 7px;
                        -ms-border-radius: 7px;
                        -o-border-radius: 7px;
                        background-color: rgba(0, 0, 0, 0.4);
                        @media (max-width:500px){
                            top: 10px;
                            right: 10px;
                            font-size: 9px;
                        }
                    }
                    img{
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 13vw;
                        min-height: 13vw;
                        @media (max-width:767px){
                            max-height: 20vw;
                            min-height: 20vw;
                        }
                        @media (max-width:500px){
                            max-height: 24vw;
                            min-height: 24vw;
                        }
                    }    
                    .texts{
                        display: flex;
                        padding: 1.2vw;
                        position: relative;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        @media (max-width:767px){
                            padding: 1.5vw;
                        }
                        @media (max-width:500px){
                            padding: 2vw;
                        }
                        .name{
                            font-size: 1vw;
                            text-align: left;                            
                            @media (max-width:1100px){
                                font-size: 1.2vw;    
                                margin-bottom: 5px;                            
                            }
                            @media (max-width:767px){
                                font-size: 1.9vw;    
                                margin-bottom: 1.3vw;                            
                            }
                            @media (max-width:500px){
                                font-size: 2.9vw;    
                                margin-bottom: 1.3vw;                            
                            }
                        }
                        .desc{
                            font-size: 1.2vw;
                            line-height: 26px;                            
                            @media (max-width:1100px){
                                font-size: 1.4vw;
                                line-height: 23px;
                            }
                            @media (max-width:767px){
                                font-size: 2.3vw;                                
                            }
                            @media (max-width:500px){
                                font-size: 2.9vw;
                                line-height: 20px;
                            }
                        }
                        .explore{
                            top: -2vw;
                            width: 4vw;
                            height: 4vw;
                            left: 1.2vw;
                            display: flex;
                            padding: 0.3vw;
                            border-radius: 50%;
                            position: absolute;
                            align-items: center;
                            transition: 2s linear;
                            text-decoration: none;
                            justify-content: center;
                            transform: rotate(-180deg);                           
                            border: 1px solid #ffffff7e; 
                            @media (max-width:767px){
                                width: 7vw;
                                height: 7vw;  
                                top: -3.5vw;    
                                padding: 0.6vw;                                                         
                            }
                            .fa-angle-right{
                                width: 100%;
                                height: 100%;
                                display: flex;                                
                                color: #5D3E32;
                                font-size: 2.5vw;
                                border-radius: 50%;
                                align-items: center;
                                justify-content: center;
                                background-color: #F2C367;
                                @media (max-width:767px){
                                    font-size: 5vw;                                                         
                                }
                            }
                        }
                        .rotate{
                            transform: rotate(360deg);
                            left: calc(100% - 5.2vw);                            
                            @media (max-width:767px){
                                left: calc(100% - 8.2vw);                                                            
                            }
                        }
                        .bottom{
                            opacity: 0.4;
                            display: flex;
                            margin-top: 0.9vw;
                            align-items: center;
                            justify-content: start;
                            @media (max-width:767px){
                                margin-top: 1.3vw;                                                                  
                            }
                            .date, .views{
                                margin: 0;
                                padding: 0;
                                font-size: 0.9vw;
                                @media (max-width:1100px){
                                    font-size: 1.1vw;                                                                   
                                }
                                @media (max-width:767px){
                                    font-size: 1.8vw;                                                                   
                                }
                                @media (max-width:500px){
                                    font-size: 2.6vw;                                                                   
                                }
                            }
                            .point{
                                width: 3.5px;
                                height: 3.5px;
                                margin: 0 10px;
                                border-radius: 50%;
                                background-color: black;
                            }
                        }
                    }
                }
            }
        }
        .more{
            width: 100%;
            display: flex;
            margin-top: 2vw;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            @media (max-width:767px){
                margin-top: 4vw;   
                font-size: 14px;                                                                
            }
            .button{
                border: 0;
                cursor: pointer;
                position: relative;
                padding: 10px 40px;
                border-radius: 7px;
                background-color: #F2C367;
                @media (max-width:500px){                     
                    font-size: 14px;      
                    padding: 7px 20px;                                                          
                }
                &:hover{
                    .second{
                        top: 0;
                    }                
                }
                .second{
                    top: -100%;                
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transition: 0.3s linear;
                    background-color: rgb(255, 255, 255);                
                }            
            }
        }
    }
    .mac1{
        top: 15vw;
        left: -1vw;
        max-width: 5vw;
        min-width: 5vw;
        // filter: blur(3px);
        position: absolute;
        animation-name: mac1;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        @media (max-width:767px){
            display: none;                                                               
        }
    }
    .mac2{
        top: 15vw;
        right: 4vw;
        max-width: 7vw;
        min-width: 7vw;
        position: absolute;
        animation-name: mac3;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        @media (max-width:767px){
            display: none;                                                               
        }
    }
}
