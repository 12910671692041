@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}
.Retsep {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  .back-image {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: 1.5s;
    object-fit: cover;
    position: absolute;
    filter: contrast(50%);
  }
  .active {
    opacity: 0.8;
  }
  .carousel {
    .carousel-page {
      width: 100%;
      height: 100vh;
      display: flex;
      padding: 5vw 15vw;
      position: relative;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        padding: 10vw;
      }
      @media (max-width: 767px) {
        height: 600px;
      }
      @media (max-width: 600px) {
        height: 500px;
      }
      @media (max-width: 500px) {
        height: 80vw;
      }
      @media (max-width: 450px) {
        padding: 7vw;
      }
      .body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 40px;
        box-shadow: 0 0 50px 10px #ffda9b78;
        @media (max-width: 767px) {
          border-radius: 3vw;
        }
        .inside-image {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
        .texts {
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          padding: 5vw;
          padding-right: 50%;
          position: absolute;
          background: linear-gradient(
            -270deg,
            rgba(95, 94, 94, 0.5254901961) 43.24%,
            rgba(220, 220, 220, 0) 62.47%
          );
          @media (max-width: 1420px) {
            padding-right: 40%;
          }
          @media (max-width: 1150px) {
            padding-right: 30%;
            padding-top: 10vw;
          }
          @media (max-width: 600px) {
            padding-right: 20%;
          }
          @media (max-width: 500px) {
            padding: 5vw;
          }
          .name {
            font-size: 35px;
            color: #fff;
            font-family: zonapro;
            @media (max-width: 1100px) {
              font-size: 2.5vw;
            }
            @media (max-width: 767px) {
              font-size: 3.5vw;
            }
            @media (max-width: 500px) {
              font-size: 4vw;
            }
          }
          .desc {
            font-size: 19px;
            color: #fff;
            margin-top: 2.5vw;
            @media (max-width: 1100px) {
              font-size: 1.8vw;
            }
            @media (max-width: 767px) {
              font-size: 2.7vw;
            }
            @media (max-width: 500px) {
              font-size: 3.5vw;
            }
          }
          .rating {
            display: flex;
            margin-top: 2.5vw;
            padding-right: 6vw;
            align-items: center;
            justify-content: space-between;
            .stars {
              .fa-star {
                opacity: 0.7;
                font-size: 25px;
                color: #fff;
                margin-right: 5px;
                @media (max-width: 1100px) {
                  font-size: 18px;
                }
                @media (max-width: 500px) {
                  font-size: 14px;
                }
              }
              .fa-star.active {
                opacity: 1;
              }
            }
            .grade {
              color: #fff;
              // text-decoration: none;

              @media (max-width: 1100px) {
                font-size: 13px;
              }
              @media (max-width: 500px) {
                font-size: 11px;
              }
            }
          }
          .link {
            left: 5vw;
            bottom: 5vw;
            position: absolute;
            .explore {
              color: black;
              text-align: center;
              padding: 13px 30px;
              text-decoration: none;
              border-radius: 7.7913px;
              -webkit-border-radius: 7.7913px;
              -moz-border-radius: 7.7913px;
              -ms-border-radius: 7.7913px;
              -o-border-radius: 7.7913px;
              border: solid 1px black;
              background: rgb(242, 196, 103);
              @media (max-width: 1100px) {
                font-size: 15px;
              }
              @media (max-width: 767px) {
                font-size: 13px;
                padding: 10px 20px;
              }
              @media (max-width: 600px) {
                font-size: 12px;
                padding: 7px 15px;
                border-radius: 5px;
              }
              @media (max-width: 500px) {
                font-size: 14px;
                padding: 7px 10px;
              }
            }
          }
        }
      }
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
    .slick-dots {
      opacity: 1;
      z-index: 4;
      bottom: 7vw;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        bottom: 12vw;
      }
      @media (max-width: 600px) {
        bottom: 9vw;
      }
      @media (max-width: 450px) {
        bottom: 0vw;
      }
      li {
        padding: 0 10px;
        @media (max-width: 450px) {
          padding: 0 6px;
        }
        button {
          &::before {
            width: 11px;
            height: 11px;
            display: flex;
            color: #fff;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            @media (max-width: 1000px) {
              width: 9px;
              height: 9px;
            }
            @media (max-width: 500px) {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      .slick-active {
        transition: 0.5s;
        button {
          transition: 0.5s;
          &::before {
            opacity: 1;
            width: 30px;
            height: 11px;
            display: flex;
            transition: 0.5s;
            margin-left: -8px;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            @media (max-width: 1000px) {
              width: 22px;
              height: 8px;
              border-radius: 4px;
            }
            @media (max-width: 500px) {
              width: 16px;
              height: 6px;
            }
          }
        }
      }
    }
  }
  .leaf {
    right: 0;
    z-index: 4;
    top: -7.8vw;
    max-width: 9vw;
    min-width: 9vw;
    position: absolute;
    transform: rotate(180deg);
  }
}
