@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}
.Sertificat2 {
  .wrapper {
    .title {
      color: #f2c367;
      font-size: 3.5vw;
      font-family: zonapro;
      text-align: center;
      padding-bottom: 3vw;
      @media (max-width: 650px) {
        font-size: 5vw;
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      border-radius: 2vw;
      align-items: center;
      background-color: #ffda9b20;
      justify-content: space-between;
      .card {
        border: 0;
        background-color: transparent;
        .body {
          padding: 5px;
          .sertificat {
            border-radius: 1vw;
            img {
              max-width: 27vw;
              min-width: 27vw;
              margin-left: 3vw;
              margin-bottom: -2.5vw;
              @media (max-width: 767px) {
                max-width: 33vw;
                min-width: 33vw;
                margin-left: 0.5vw;
              }
              @media (max-width: 650px) {
                max-width: 33vw;
                min-width: 33vw;
                margin-left: 2.5vw;
              }
              @media (max-width: 500px) {
                max-width: 37vw;
                min-width: 37vw;
                margin-left: 2.3vw;
              }
            }
            h3 {
              font-size: 1.3vw;
              padding: 0 5vw;
              color: #f2c367;
              text-align: center;
              font-family: zonapro;
              @media (max-width: 767px) {
                font-size: 1.5vw;
              }
              @media (max-width: 650px) {
                font-size: 1.8vw;
              }
              @media (max-width: 500px) {
                font-size: 2.5vw;
              }
            }
          }
        }
      }
      .btnlar {
        display: flex;
        padding: 1.2vw 2.5vw;
        align-items: center;
        background: #f2c367;
        margin: 5vw 0 4vw 25vw;
        justify-content: center;
        border-radius: 9.34956px;
        border: 1.1687px solid #3f2328;
        cursor: pointer;
        @media (max-width: 1000px) {
          margin: 5vw 0 4vw 27vw;
        }
        @media (max-width: 700px) {
          margin: 5vw 0 4vw 29.5vw;
        }
        @media (max-width: 500px) {
          margin: 5vw 0 4vw 27vw;
        }
        .buttons {
          color: black;
          font-size: 1.3vw;
          font-family: zonapro;
          text-decoration: none;
          @media (max-width: 767px) {
            font-size: 1.7vw;
          }
          @media (max-width: 500px) {
            font-size: 2.7vw;
          }
        }
      }
    }
  }
}
