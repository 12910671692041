@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}

.Header {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: sticky;
  background-color: #3f2328;

  @media (max-width: 767px) {
    height: auto;
  }

  .carousel {
    margin: 0;
    padding: 0;

    .slick-dots {
      opacity: 1;
      z-index: 4;
      bottom: 5vw;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1100px) {
        bottom: 6vw;
      }

      @media (max-width: 600px) {
        bottom: 9vw;
      }

      @media (max-width: 450px) {
        bottom: 6vw;
      }

      li {
        padding: 0 30px;

        @media (max-width: 450px) {
          padding: 0 17px;
        }

        button {
          &::before {
            opacity: 1;
            width: 25px;
            height: 25px;
            display: flex;
            color: #fff;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #fff;

            @media (max-width: 1000px) {
              width: 18px;
              height: 18px;
            }

            @media (max-width: 500px) {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .slick-active {
        transition: 0.5s;

        button {
          transition: 0.5s;

          &::before {
            opacity: 1;
            width: 55px;
            height: 25px;
            display: flex;
            transition: 0.5s;
            margin-left: -15px;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;

            @media (max-width: 1000px) {
              width: 40px;
              height: 18px;
              border-radius: 16px;
              -webkit-border-radius: 16px;
              -moz-border-radius: 16px;
              -ms-border-radius: 16px;
              -o-border-radius: 16px;
            }

            @media (max-width: 500px) {
              width: 28px;
              height: 12px;
              margin-left: -5px;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    padding-top: 200px;
    position: relative;
    align-items: flex-end;
    padding-bottom: 150px;
    mix-blend-mode: normal;
    display: flex !important;
    // background: #f2c4677d;
    // backdrop-filter: blur(50px);
    justify-content: space-between;

    @media (max-width: 1400px) {
      padding: 0 12vw;
      padding-top: 200px;
      padding-bottom: 150px;
    }

    @media (max-width: 800px) {
      padding: 0 12vw;
      padding-top: 150px;
      padding-bottom: 150px;
    }

    @media (max-width: 767px) {
      height: 600px;
    }

    @media (max-width: 600px) {
      display: block;
      // padding: 0 12vw;
      // padding-top: 140px;
      // padding-bottom: 140px;
    }

    @media (max-width: 430px) {
      // padding: 0 10vw;
      // padding-top: 90px;
      // padding-bottom: 40px;
      height: 500px;
    }

    .texts {
      width: 41%;
      // margin-bottom: 6vw;

      @media (max-width: 800px) {
        padding-right: 0vw;
        width: 60%;
      }

      @media (max-width: 600px) {
        width: 100%;
        padding: 0.9vw;
      }

      .title {
        color: #fff;
        font-size: 3vw;
        text-align: left;
        margin-top: -2.5vw;
        font-family: zonapro;
        &::first-letter {
          text-transform: uppercase;
        }

        @media (max-width: 1000px) {
          font-size: 4.4vw;
        }

        @media (max-width: 800px) {
          font-size: 5vw;
        }

        @media (max-width: 600px) {
          font-size: 6vw;
          margin: 2vw 0 5vw 0;
        }

        @media (max-width: 430px) {
          font-size: 5vw;
          margin: -15vw 0 5vw 0;
        }
      }

      .text {
        color: white;
        margin-top: 1vw;
        font-size: 1.3vw;
        text-align: left;

        @media (max-width: 1000px) {
          margin-top: 2vw;
          font-size: 1.8vw;
        }

        @media (max-width: 800px) {
          font-size: 2.8vw;
        }

        @media (max-width: 600px) {
          font-size: 4vw;
          margin: 0 0 10vw 0;
        }

        @media (max-width: 430px) {
          font-size: 3.9vw;
        }
      }

      .btns {
        display: flex;
        margin-top: 3vw;
        align-items: center;
        justify-content: start;

        @media (max-width: 1000px) {
          margin-top: 2vw;
        }

        @media (max-width: 600.1px) {
          margin-top: 2vw;
          // padding-top: 65vw;
        }

        .retsep,
        .other {
          color: black;
          font-weight: 500;
          padding: 0.5vw 1vw;
          border-radius: 86.4px;
          text-decoration: none;
          background-color: #f2c367;
          border: 1.1687px solid #3f2328;
          margin-bottom: 3vw;
          @media (max-width: 1000px) {
            font-size: 1.2vw;
          }

          @media (max-width: 800px) {
            font-size: 13px;
          }

          @media (max-width: 600px) {
            font-size: 18px;
          }

          @media (max-width: 430px) {
            font-size: 13.5px;
            padding: 2vw 1vw;
            border-radius: 5vw;
          }

          &:hover {
            color: #000;
            background-color: #fff;
          }
        }

        .other {
          margin-left: 2vw;
        }
      }
      .batafsil {
        // position: absolute;

        margin: 0;
        font-size: 1.3vw;
        color: white;
        padding: 3vw 0 0 0;
        font-family: zonapro;

        a {
          color: white;
        }
        @media (max-width: 1000px) {
          font-size: 1.2vw;
        }

        @media (max-width: 800px) {
          font-size: 13px;
        }

        @media (max-width: 600px) {
          font-size: 18px;
        }

        @media (max-width: 430px) {
          font-size: 13px;
          // border-radius: 0.8vw;
        }
      }
    }

    .cards {
      display: flex;
      justify-content: center;

      @media (max-width: 600px) {
        width: 100%;
        margin-top: 4vw;
      }

      .random-card {
        display: flex;
        padding: 0 1.1vw;
        margin-bottom: -4vw;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: 800px) {
          padding: 0 0.9vw;
        }

        @media (max-width: 600px) {
          margin-bottom: 0;
        }

        .body {
          height: 100%;
          display: flex;
          padding: 0.8vw;
          cursor: pointer;
          align-items: center;
          border-radius: 1.1vw;
          flex-direction: column;
          border: solid 1px black;
          background-color: #f2c367;
          justify-content: space-between;

          @media (max-width: 600px) {
            padding: 1.3vw;
            border-radius: 1.3vw;
          }

          img {
            max-width: 100%;
            // min-width: 100%;
            max-height: 7vw;
            min-height: 7vw;
            border-radius: 0.7vw;

            @media (max-width: 1400px) {
              max-height: 8vw;
              min-height: 8vw;
            }

            @media (max-width: 800px) {
              max-height: 9vw;
              min-height: 9vw;
            }

            @media (max-width: 600px) {
              max-height: 14vw;
              min-height: 14vw;
            }

            @media (max-width: 430px) {
              max-height: 16vw;
              min-height: 16vw;
            }
          }

          .name {
            margin: 0;
            margin-top: 1vw;
            font-size: 0.9vw;
            line-height: 1vw;
            text-align: center;

            @media (max-width: 1000px) {
              font-size: 1.2vw;
              line-height: 1.3vw;
            }

            @media (max-width: 800px) {
              font-size: 1.7vw;
              line-height: 1.8vw;
            }

            @media (max-width: 600px) {
              font-size: 2.5vw;
              line-height: 2.6vw;
            }

            @media (max-width: 430px) {
              font-size: 3.2vw;
              line-height: 3.3vw;
            }
          }
        }
      }
    }

    .back-img {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;

      @media (max-width: 600px) {
        filter: brightness(0.6);
        right: 0;
        left: auto;
        width: 150%;
        height: 125%;
        top: -12.5vw;
      }

      @media (max-width: 430px) {
        width: 180%;
      }
    }

    .back-img1 {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;

      @media (min-width: 600.1px) {
        display: none;
      }

      // @media(max-width:430px){
      //     width: 180%;
      // }
    }

    .texture-img {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      position: absolute;

      @media (max-width: 600px) {
        width: 150%;
      }
    }

    .side-img {
      left: 0;
      bottom: 0;
      max-width: 11vw;
      min-width: 11vw;
      position: absolute;
    }

    .mac-img1 {
      top: 150px;
      z-index: 3;
      max-width: 8vw;
      min-width: 8vw;
      position: absolute;
      animation-name: mac1;
      left: calc(50% - 4vw);
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      @media (max-width: 1400px) {
        top: 120px;
      }

      @media (max-width: 800px) {
        top: 100px;
      }

      @media (max-width: 600px) {
        top: 150px;
        left: 90%;
      }
    }

    .mac-img2 {
      z-index: 3;
      top: 280px;
      left: 7.5vw;
      max-width: 8vw;
      min-width: 8vw;
      position: absolute;
      animation-name: mac2;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      @media (max-width: 1400px) {
        top: 230px;
        left: 4.5vw;
      }

      @media (max-width: 800px) {
        top: 180px;
        left: 0;
      }

      @media (max-width: 600px) {
        top: 300px;
        left: 0;
      }
    }
  }
}

@keyframes mac1 {
  50% {
    margin-left: 20px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes mac2 {
  50% {
    margin-top: 20px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes mac3 {
  50% {
    margin-right: 20px;
  }

  100% {
    margin-right: 0;
  }
}
