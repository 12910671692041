@font-face {
  font-family: zonapro;
  src: url("../../../assets/fonts/zonapro.ttf");
}
.Contacts {
  margin: 0;
  padding: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: sticky;
  overflow: hidden;
  .title-contact {
    color: #f2c367;
    font-size: 3.3vw;
    text-align: center;
    font-family: zonapro;
    @media (max-width: 1200px) {
      font-size: 3.8vw;
    }
    @media (max-width: 700px) {
      font-size: 4.5vw;
    }
    @media (max-width: 500px) {
      font-size: 5.5vw;
    }
    @media (max-width: 400px) {
      font-size: 6vw;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (max-width: 1150px) {
      padding: 50px 13vw;
    }
    @media (max-width: 1000px) {
      padding: 50px 10vw;
    }
    @media (max-width: 650px) {
      display: block;
      padding-top: 20px;
    }
    .texts {
      padding: 20px;
      @media (max-width: 900px) {
        padding: 15px;
      }
      @media (max-width: 800px) {
        padding: 10px;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
      .desc {
        opacity: 0.7;
        color: white;
        font-size: 20px;
        text-align: left;
        font-weight: 300;
        // margin-top: -6vw;
        @media (max-width: 900px) {
          font-size: 16px;
        }
        @media (max-width: 800px) {
          font-size: 15px;
        }
        @media (max-width: 400px) {
          font-size: 14px;
        }
      }
      .desc1 {
        font-family: zonapro;
      }
      .title {
        font-size: 45px;
        color: #f2c367;
        text-align: left;
        font-family: zonapro;
        @media (max-width: 1100px) {
          font-size: 37px;
        }
        @media (max-width: 900px) {
          font-size: 30px;
        }
        @media (max-width: 800px) {
          font-size: 25px;
        }
        @media (max-width: 400px) {
          font-size: 22px;
        }
      }
    }
    .form {
      padding: 40px;
      @media (max-width: 900px) {
        padding: 15px;
      }
      @media (max-width: 800px) {
        padding: 10px;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
      .body {
        padding: 20px 30px;
        border-radius: 30px;
        border: solid 2px rgba(255, 255, 255, 0.386);
        @media (max-width: 800px) {
          padding: 20px;
          border-radius: 23px;
        }
        @media (max-width: 400px) {
          border-radius: 19px;
        }
        .inputs {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          .limit {
            bottom: 5px;
            width: 2px;
            height: 55%;
            position: absolute;
            transform: rotate(15deg);
            background-color: rgba(255, 255, 255, 0.386);
          }
        }
        .form-control {
          border: none;
          color: white;
          padding: 10px 0;
          border-radius: 0;
          box-shadow: none;
          font-weight: 300;
          background-color: transparent;
          border-bottom: solid 2px rgba(255, 255, 255, 0.386);
          &::placeholder {
            opacity: 0.7;
            color: white;
          }
          @media (max-width: 800px) {
            font-size: 15px;
          }
        }
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: 0;
        }
        .form1 {
          font-family: zonapro;
        }
        .number {
          margin-top: 30px;
          @media (max-width: 800px) {
            margin-top: 15px;
          }
        }
        .pdl {
          padding-right: 20px;
        }
        .email {
          padding-left: 20px;
        }
        .red-line {
          color: #e1053f;
          border-bottom: solid 2px #e1053f;
          &::placeholder {
            color: #e1053f;
          }
        }
        .form-text {
          outline: none;
          width: 100%;
          padding: 10px;
          color: white;
          border-radius: 0;
          box-shadow: none;
          resize: none;
          margin-top: 20px;
          font-weight: 300;
          border-radius: 20px;
          background-color: transparent;
          border: solid 2px rgba(255, 255, 255, 0.386);
          &::placeholder {
            opacity: 0.7;
            color: white;
          }
          @media (max-width: 800px) {
            font-size: 15px;
          }
        }
        .send {
          width: 100%;
          border: none;
          outline: none;
          color: black;
          padding: 13px 0;
          margin-top: 30px;
          font-family: zonapro;
          font-weight: 600;
          background-color: #f2c367;
          @media (max-width: 800px) {
            padding: 8px 0;
            font-size: 15px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
