*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // background: url('./assets/images/back.png') no-repeat;
  // background-size: 100% 100%;
  background-color: #452e32 !important;
}