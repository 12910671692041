@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
@font-face {
    font-family: zonapro1;
    src: url("../../../assets/fonts/zonapro1.ttf");
}
.OneNews{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    .card-new{
        display: flex;
        flex-wrap: wrap;        
        padding-bottom: 0;        
        padding-top: 170px;
        align-items: center;
        justify-content: space-between;
        @media (max-width:767px){
            padding-top: 130px;
        }
        @media (max-width:500px){
            padding-top: 100px;
            margin-bottom: -20px;            
        }
        @media (max-width:400px){
            padding-top: 80px;
        }        
        .name{
            margin: 0;
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .image{
            margin-top: 2vw;
            padding: 0 1.5vw;
            @media (max-width:767px){
                width: 100%;
                margin-top: 3vw;
            }
            img{
                max-width: 100%;
                min-width: 100%;
            }
        }
        .text{
            margin-top: 2vw;
            padding: 0 1.5vw;
            @media (max-width:767px){
                width: 100%;
                margin-top: 3vw;
            }
            p{
                font-size: 18px;
                color: #F2C367;
                @media (max-width:1500px){
                    font-size: 16px;
                }
                @media (max-width:1300px){
                    font-size: 15px;
                }
                @media (max-width:900px){
                    font-size: 14px;
                }
            }
        }
    }
}