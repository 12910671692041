@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}
.About{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    position: sticky;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    .wrapper{
        padding-top: 170px;
        @media (max-width:767px){
            padding-bottom: 0;
            padding-top: 130px;
        }
        @media (max-width:500px){
            padding-top: 100px;
        }
        @media (max-width:400px){
            padding-top: 80px;
        }
        .title{
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .about-body{
            display: flex;
            flex-wrap: wrap;
            margin-top: 4vw;
            align-items: center;
            justify-content: center;
            @media (max-width:767px){
                display: block;
            }
            .text{
                padding-right: 5vw;
                @media (max-width:767px){
                    padding: 0;
                    width: 100%;
                }
                p{
                    color: #F2C367;
                    text-align: left;
                    font-size: 1.2vw;
                    overflow: hidden;
                    line-height: 1.8vw;
                    margin: 0;
                    @media (max-width:1200px){
                        font-size: 1.5vw;
                    }
                    @media (max-width:900px){
                        font-size: 2vw;
                        line-height: 2.5vw;
                    }
                    @media (max-width:600px){
                        font-size: 2.5vw;
                        line-height: 3vw;
                        text-align: justify;
                    }
                    @media (max-width:500px){
                        font-size: 3.2vw;
                        line-height: 3.5vw;
                    }
                }
            }
            .img{                
                height: 30vw;
                overflow: hidden;
                border-radius: 2vw;
                border: solid 1px white;
                @media (max-width:767px){
                    width: 100%;
                    margin-top: 4vw;
                    height: 50vw;
                }                
                iframe{
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 100%;
                    min-height: 100%;
                }
            }
        }
    }
    .square, .square1, .square2, .square3, .square4{
        width: 2vw;
        height: 2vw;
        z-index: -1;
        bottom: -10%;
        opacity: 0.8;
        position: absolute;
        animation-duration: 11s;
        animation-name: squareAnim;
        border: solid 3px #ffd98e;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
    .square1{
        left: 10%;
        animation-delay: 0.5s;
        animation-duration: 11s;
    }
    .square2{
        left: 90%;
        width: 1vw;
        height: 1vw;
        animation-duration: 12s;
    }
    .square3{
        left: 40%;
        width: 3vw;
        height: 3vw;
        animation-delay: 1.5s;
        animation-duration: 10s;
    }
    .square4{
        left: 70%;
        width: 2.5vw;
        height: 2.5vw;
        animation-delay: 0.5s;
        animation-duration: 13s;
    }
}

@keyframes squareAnim {
    100%{
        opacity: 0;
        bottom: 100%;
        transform: rotate(180deg) scale(2);
    }
}