@font-face {
    font-family: zonapro;
    src: url("../../../assets/fonts/zonapro.ttf");
}

.Product {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;

    .wrapper {
        padding-top: 170px;

        @media (max-width:767px) {
            padding-bottom: 0;
            padding-top: 130px;
        }

        @media (max-width:500px) {
            padding-top: 100px;
            padding-bottom: 30px;
        }

        @media (max-width:400px) {
            padding-top: 80px;
        }

        .one-product {
            display: flex;
            padding: 2vw;
            margin-bottom: 2vw;
            justify-content: space-between;

            @media (max-width:767px) {
                display: block;
            }

            .texts {
                padding-right: 2vw;

                @media (max-width:767px) {
                    padding: 0;
                    width: 100%;
                }

                .body {
                    height: 100%;
                    padding: 2vw;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    backdrop-filter: blur(5px);
                    background-color: rgba(255, 255, 255, 0.1);
                    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);

                    @media (max-width:1100px) {
                        padding: 3vw;
                    }

                    .name {
                        color: #F2C367;
                        font-size: 2.5vw;

                        @media (max-width:1100px) {
                            font-size: 3vw;
                        }

                        @media (max-width:767px) {
                            font-size: 4.5vw;
                        }

                        @media (max-width:500px) {
                            font-size: 5.5vw;
                        }
                    }

                    .desc {
                        color: white;
                        font-size: 1.1vw;
                        margin-top: 1.5vw;

                        @media (max-width:1100px) {
                            font-size: 1.6vw;
                        }

                        @media (max-width:767px) {
                            font-size: 2.5vw;
                        }

                        @media (max-width:500px) {
                            font-size: 3.5vw;
                        }
                    }

                    .support {
                        border: none;
                        outline: none;
                        color: #3F2328;
                        margin-top: 1.5vw;
                        padding: 0.5vw 2vw;
                        border-radius: 0.6vw;
                        background-color: #F2C367;
                        background-color: #F2C367;
                        color: #3F2328;
                        border-color: #F2C367;

                        @media (max-width:767px) {
                            border-radius: 1vw;
                        }

                        @media (max-width:500px) {
                            padding: 1vw 4vw;
                        }

                        &:hover {
                            color: white;
                            backdrop-filter: blur(5px);
                            background-color: rgba(255, 255, 255, 0.1);
                            box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }

            .image {
                padding-left: 2vw;

                @media (max-width:767px) {
                    padding: 0;
                    width: 100%;
                    margin-top: 4vw;
                }

                .body {
                    height: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: 100%;
                        min-width: 100%;
                        border-radius: 1.5vw;
                        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
                    }
                }
            }
        }

        .title {
            color: #F2C367;
            font-size: 3.3vw;
            text-align: center;
            font-family: zonapro;

            @media (max-width:1200px) {
                font-size: 3.8vw;
            }

            @media (max-width:700px) {
                font-size: 4.5vw;
            }

            @media (max-width:500px) {
                font-size: 5.5vw;
            }

            @media (max-width:400px) {
                font-size: 6vw;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            margin-top: 5vw;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }

            @media (max-width:500px) {
                margin-top: 0;
                grid-template-columns: repeat(1, 1fr);
            }

            .product {
                padding: 0 1vw 5vw;

                @media (max-width:767px) {
                    width: 100%;
                }

                @media (max-width:500px) {
                    margin-top: 11vw;
                }

                .body {
                    display: flex;
                    padding: 1.5vw;
                    transition: 0.4s;
                    position: relative;
                    border-radius: 1vw;
                    text-decoration: none;
                    flex-direction: column;
                    align-items: flex-start;
                    backdrop-filter: blur(5px);
                    background-color: rgba(255, 255, 255, 0.1);
                    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
                    border-top: solid 1px rgba(255, 255, 255, 0.5);
                    border-left: solid 1px rgba(255, 255, 255, 0.5);

                    @media(max-width:500px) {
                        padding: 2.5vw;
                        border-radius: 2.5vw;
                    }

                    &::before {
                        top: 0;
                        left: 0;
                        width: 0;
                        content: "";
                        z-index: -1;
                        height: 100%;
                        transition: 0.4s;
                        position: absolute;
                        border-radius: 1vw;
                        background-color: #F2C367;
                    }

                    &:hover {
                        transform: scale(1.05);

                        &::before {
                            width: 100%;
                        }

                        .stamp {
                            color: #F2C367;
                            background-color: #3F2328;
                        }

                        .name,
                        .desc {
                            color: #3F2328;
                        }
                    }

                    img {
                        object-fit: contain;
                        top: -3vw;
                        width: 6vw;
                        height: 6vw;
                        border-radius: 50%;
                        position: absolute;
                        border: solid 1px white;
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

                        @media(max-width:767px) {
                            top: -5vw;
                            width: 10vw;
                            height: 10vw;
                        }

                        @media(max-width:500px) {
                            top: -9vw;
                            width: 18vw;
                            height: 18vw;
                        }
                    }

                    .stamp {
                        right: 2vw;
                        font-size: 0.8vw;
                        padding: 2px 5px;
                        color: #3F2328;
                        transition: 0.4s;
                        position: absolute;
                        border-radius: 0.3vw;
                        transform: rotate(15deg);
                        background-color: #F2C367;

                        @media(max-width:767px) {
                            font-size: 1.5vw;
                            border-radius: 0.7vw;
                        }

                        @media(max-width:500px) {
                            top: 4vw;
                            right: 4vw;
                            font-size: 2.5vw;
                            border-radius: 1vw;
                        }
                    }

                    .name {
                        color: white;
                        margin-top: 2vw;
                        transition: 0.4s;
                        font-size: 1.5vw;

                        @media (max-width:1100px) {
                            font-size: 1.8vw;
                        }

                        @media (max-width:767px) {
                            margin-top: 5vw;
                            font-size: 2.5vw;
                        }

                        @media (max-width:500px) {
                            margin-top: 9vw;
                            font-size: 4.5vw;
                        }
                    }

                    .desc {
                        opacity: 0.8;
                        color: white;
                        font-size: 1vw;
                        margin-bottom: 0;
                        transition: 0.4s;

                        @media (max-width:1100px) {
                            font-size: 1.3vw;
                        }

                        @media (max-width:767px) {
                            font-size: 1.8vw;
                        }

                        @media (max-width:500px) {
                            font-size: 3.5vw;
                        }
                    }
                }
            }
        }
    }
}