.OneCategory{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        padding-top: 170px;
        @media (max-width:767px){
            padding-top: 130px;
        }
        @media (max-width:500px){
            padding-top: 100px;
        }
        @media (max-width:400px){
            padding-top: 80px;
        }
        .title{
            color: #F2C367;
            font-size: 3.3vw;            
            text-align: center;
            font-family: zonapro;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .cards{
            display: flex;
            margin-top: 2vw;
            flex-wrap: wrap;
            justify-content: space-between;
            @media (max-width:767px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .product{
                padding: 2vw;
                @media (max-width:767px){
                    width: 100%;
                }
                @media (max-width:500px){
                    padding: 1.5vw;
                }
                .body{
                    height: 22vw;
                    display: flex;
                    overflow: hidden;
                    position: relative;
                    border-radius: 1.5vw;
                    text-decoration: none;
                    align-items: flex-end;
                    justify-content: center;
                    // border: solid 1px white;
                    background-color: rgba(255, 255, 255, 0.166);
                    @media (max-width:767px){
                        height: 35vw;
                        border-radius: 2vw;
                    }
                    @media (max-width:500px){
                        height: 40vw;                        
                    }
                    &:hover{
                        .texts{   
                            padding-top: 0;
                            &::before{
                                top: -30%;                            
                            }   
                            .explore{
                                visibility: visible;
                                top: calc(-30% - 1.5vw);
                                @media (max-width:767px){
                                    top: calc(-30% - 2.5vw);
                                }
                            }      
                            .desc{
                                margin-bottom: 0;
                            }                
                        }
                    }
                    img{
                        top: 5%;
                        // left: 0;
                        // z-index: -1;
                        // width: 100%;
                        height: 75%;
                        position: absolute;
                        // object-fit: cover;
                    }
                    .texts{
                        width: 100%;
                        padding: 1vw;
                        display: flex;
                        transition: 0.3s;
                        position: sticky;
                        z-index: 2;
                        align-items: center;
                        flex-direction: column;
                        background-color: #F2C367;
                        justify-content: space-between;
                        @media (max-width:767px){
                            padding: 1vw 1.7vw;
                        }
                        @media (max-width:500px){
                            padding: 1.7vw;
                        }
                        &::before{
                            top: 0;
                            width: 230%;
                            z-index: -1;
                            content: "";
                            height: 500%;
                            padding: 1vw 0;
                            transition: 0.3s;
                            position: absolute;                         
                            border-radius: 50%;
                            background-color: #F2C367;
                        }
                        .name{
                            font-size: 1vw;
                            color: #3F2328;
                            margin-bottom: 1vw;
                            text-transform: lowercase;
                            @media (max-width:1100px){
                                font-size: 1.5vw;
                            }
                            @media (max-width:767px){
                                font-size: 2.2vw;
                                margin-bottom: 0.7vw;
                            }
                            @media (max-width:500px){
                                font-size: 2.7vw;                                
                            }
                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                        .desc{
                            font-size: 0.9vw;
                            color: #3F2328;
                            transition: 0.3s;
                            margin-bottom: -3.6vw;
                            @media (max-width:1100px){
                                font-size: 1.2vw;
                                margin-bottom: -6.6vw;
                            }
                            @media (max-width:767px){
                                font-size: 1.8vw;
                                margin-bottom: -6vw;
                            }
                            @media (max-width:500px){
                                font-size: 2.2vw;
                                margin-bottom: -8vw;
                            }
                        }
                        .explore{
                            top: 0;
                            width: 3vw;
                            height: 3vw;
                            z-index: -1;
                            display: flex;
                            transition: 0.3s;
                            position: absolute;
                            visibility: hidden;
                            border-radius: 50%;
                            align-items: center;
                            text-decoration: none;
                            justify-content: center;
                            background-color: #F2C367;
                            border: solid 1px #3F2328;
                            @media (max-width:767px){
                                width: 5vw;
                                height: 5vw;
                            }
                            .fa{
                                font-size: 2vw;
                                color: #3F2328;
                                @media (max-width:767px){
                                    font-size: 3.5vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}